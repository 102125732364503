<template>
  <tbody>
    <tr>
      <td>{{ ((currentpage - 1) * noofrec) + (indx + 1) }}</td>
      <td>{{ product.name }}</td>
      <td>
        <div v-if="product.category != null">{{ product.category.name }}</div>
      </td>
      <td>
        <div v-for="item in product.attribute" v-bind:key="item.id" :item="item">
          {{ getcompany(item.attribute_value_ids) }}
        </div>
      </td>
      <!--<td><div v-if="product.attribute!=null" >{{product.attribute.attribute_value_ids}}</div></td>-->
      
      <td>{{ product.modelno }}</td>
      <td>{{ getColour(product) }}</td>
      <td>
        <span>{{ product.description }}</span>
      </td>
      <td>
        <button class="btn btn-Success btn-xs " @click="triggeredit(product)">
          <i class="fa fa-pencil-square-o"></i>
        </button>
        <button v-if="false" class="btn btnsearch btn-view btn-xs" type="button" @click="payemi()" style="
              background-color: #179b17 !important;
              padding: 7px;
              font-size: 9px;
              border-radius: 25px;
            ">
          <i class="fa fa-arrow-up"></i>Pay Emi
        </button>
      </td>
      <td>
        <button type="button" v-if="product.disabled == 0" class="btn btn-error btn-xs" style="margin-bottom:5px;"
          @click="updatedefault(product)">
          Disabled</button>
        <button type="button" v-else class="btn btn-Success btn-xs" style="margin-bottom:5px;"
          @click="updatedefault(product)">
          Enabled</button>
      </td>
    </tr>
    <tr v-if="showpaymentform">
      <td colspan="9">
        <set-emi-price-form></set-emi-price-form>
      </td>
    </tr>
  </tbody>
</template>
<script>
import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";

export default {
  mixins: [Constants],
  components: {},
  props: ["product", "indx", "noofrec", "currentpage"],
  data() {
    return {
      showpaymentform: false,
      featureprod: [],
      selectedcategories: [],
      ready: false,
      disabled: false,
      onsale: false,
      outofstock: false,
      trynbuy: false,
      emifield: false,
      spfield: false
    };
  },
  mounted() {
    this.initialize();
    this.ready = true;
  },
  watch: {
    product: function () {
      this.initialize();
    },
    feature: function () {
      this.featurespilit();
    },
  },
  computed: {
    getproducturl() {
      return window.axios.defaults.baseURL + "/" + this.product.url;
    },
    ...mapGetters([
      "categories",
      "feature",
      "perpagerecord",
      "attributes",
      'viewno'
    ]),
  },
  methods: {
    getcompany(attribute_value_ids) {
      let brand = this.attributes.find((block) => block.id == +2);
      if (typeof brand !== "undefined") {
        let companyname = brand.predefinedvalues.find(
          (a) => a.id == attribute_value_ids
        );
        if (typeof companyname != "undefined") {
          return companyname.value;
        }
      }
      return attribute_value_ids;
    },
    initialize() {
      if (this.product != null) {
        if (this.product.categoryids != null)
          this.selectedcategories = this.product.categoryids.split(",");
        this.disabled = this.product.disabled == 0 ? false : true;
        this.onsale = this.product.onsale == 0 ? false : true;
        this.outofstock = this.product.outofstock == 0 ? false : true;
        this.trynbuy = this.product.trynbuy == 0 ? false : true;
      }
    },
    featurespilit() {
      if (this.product != null) {
        if (this.product.featureid != 0)
          this.featureprod = this.product.featureid.split(",");
      }
    },
    getsalelabel(product) {
      if (product.status == 0) {
        return "Sale";
      }
    },
    updatefeature() {
      let param = {
        productid: this.product.id,
        featureids: this.featureprod.join(),
      };
      this.$http
        .post("feature/updatefeature", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },

    trynbuyevent() {
      let param = { productid: this.product.id, trynbuy: this.trynbuy ? 0 : 1 };
      this.$http
        .post("api/product/updatetrynbuy", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    outofstockevent() {
      let param = {
        productid: this.product.id,
        outofstock: this.outofstock ? 0 : 1,
      };
      this.$http
        .post("api/product/updateoutofstockstatus", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    onsaledevent() {
      let param = { productid: this.product.id, onsale: this.onsale ? 0 : 1 };
      this.$http
        .post("api/product/updateonsalestatus", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    disabledevent() {
      let param = {
        productid: this.product.id,
        disabled: this.disabled ? 1 : 0,
      };
      this.$http
        .post("api/product/updatedisablestatus", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    updatecategory() {
      let param = {
        productid: this.product.id,
        categoryids: this.selectedcategories.join(),
      };
      this.$http
        .post("api/product/updatecategory", param)
        .then((response) => this.processProductCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    getcategoryobject(item) {
      let found = this.categories.find((block) => block.id == item);
      if (typeof found !== "undefined") {
        return found;
      }
      return null;
    },
    processProductCategoryResponse(data) {
      if (!data) {
        alert("Could not update");
      }
    },
    processResponse(data) {
      this.$store.commit("createupdateallproducts", data);
    },
    payemi() {
      this.showpaymentform = true;
    },
    showemi(product) {
      this.emifield = !this.emifield
      let param = { id: product.id, column: "emi", value: this.product.emi }
      this.$http.post('api/product/updatefield', param)
        .then((response) => this.processUpdateResponse(response.data))
        .catch((err) => {
          console.log(err)
        });
    },
    showsp(product) {
      this.spfield = !this.spfield

      let param = { id: product.id, column: "sp", value: this.product.sp }
      this.$http.post('api/product/updatefield', param)
        .then((response) => this.processUpdateResponse(response.data))
        .catch((err) => {
          console.log(err)
        });
    },
    processUpdateResponse(data) {
      this.$notify({
        text: 'Field Updated Successfully', data,
        type: 'success',
        duration: '-1',
        speed: '100',
        closeOnClick: false
      })
    },
    updatedefault(product) {
      if (confirm("Do you really want to delete the Product") == true) {
        this.$store.commit('assignloadingstatus', 1)
        let parm = { id: product.id, column: "disabled", value: this.product.disabled == 0 ? 1 : 0 };
        this.$http.post('api/product/updatefield', parm)
          .then((response) => this.processDisabledResponse(response.data))
          .catch((err) => {
            console.log(err)
          });
      }
    },
    processDisabledResponse() {
      this.$parent.refresh();
      this.$notify({
        text: 'Product Has been disabled Successfully',
        type: 'success',
        duration: '-1',
        speed: '100',
        closeOnClick: false
      })
      this.$store.commit('assignloadingstatus', 0)
      this.$parent.refresh();
    },
    getColour(item){
      if(item.attributejson!=null){
        let colour = JSON.parse(item.attributejson)
        return colour?.colour
      }else {
        return "N/A"
      }
    }
    
  }
}
</script>
<style scoped>
input {
  border: none;
  width: 100%;
  height: 100%;
}
</style>