<template>
	<div>
		<div v-if="viewno == 1">
			<div class="col-md-12 font">
				<div class="cardbox">
					<div class="col-md-12">
						<div class="row" style="margin-top: 20px">
							<div class="col-md-6">
								<h3 class="page-header">
									<i class="fa fa-linode animated bounceInDown show-info"></i>
									Model Edit , View , Disabled
								</h3>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-10">
										<div class="row" style="padding-top: 18px; justify-content: flex-end">
											<div class="col-md-2" style="margin-right: 25px">
												<button @click="refresh()" class="btn btn-primary">
													<i class="fa fa-refresh"></i> Refresh
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="memberdivtable_1">
						<div class="card-body" style="border: 1px solid #e9dbdb">
							<div class="form-group mb-1">
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-4">
											<p><b>Product Name</b></p>
											<!-- <autocomplete ref="autocomplete" :source="distributionGroupsEndpoint"
												input-class="form-control form-cascade-control input-box-short"
												results-property="data" :results-display="formattedDisplay"
												v-model="searchcategory" @selected="addDistributionGroup">
											</autocomplete> -->

											<!-- :request-headers="authHeaders" -->
											<select v-model="categoryid"
												class="form-control form-cascade-control input-small">
												<option v-for="filter in filters" :key="filter.id"
													:value="filter.id">{{ filter.name }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<p><b>Company Name</b></p>
											<select class="form-control form-cascade-control input-small .dropdown-menu"
												v-model="brandid">
												<option v-for="item in companies" :key="item.id" :value="item.id">{{
													item.value }}
												</option>
											</select>
											<!-- <input type="Search" v-model="searchname" class=" form-control form-cascade-control input-box-short " /> -->
										</div>
										<!-- <div class="col-md-3">
											<p><b>Product Name</b></p>
											<input type="Search" v-model="searchname"
												class="form-control form-cascade-control input-box-short " />
										</div> -->
										<div class="col-md-4">
											<p><b>Model Name</b></p>
											<input type="Search" v-model="searchname"
												class="form-control form-cascade-control input-box-short " />
										</div>
										
									</div>
								</div>
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-2 flex-around-row" style="margin-top: 34px">
											<button type="button"
												@click="disabledproducts ? fetchDisabledProduct() : search()"
												class="btn bg-success text-white username btnsearch">
												Search
											</button>
											<!-- <button type="button" v-if="!disabledproducts" @click="search()"
												class="btn bg-success text-white username btnsearch">
												Search
											</button> -->

											<button type="button" @click="cancel()"
												class="btn bg-danger text-white username btnsearch">
												Cancel
											</button>
										</div>
										<div class="col-md-10 text-right">
											<button v-if="disabledproducts" type="button" class="btn btn-prime btn-xs"
												@click="showdisabled()">
												Show Enabled
											</button>
											<button v-else type="button" class="btn btn-error btn-xs"
												@click="showdisabled()">
												Show Disabled
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--<div class="col-md-12" style="margin-top: 20px">
            <nav
              aria-label="Page navigation example"
              style="text-align: right !important"
            >
               <paginate
                style="margin: 0px !important; float: right"
                :page-count="noofrec"
                :click-handler="clickCallback"
                :prev-text="'&laquo; Prev &nbsp;&nbsp;'"
                :next-text="'&nbsp;&nbsp; Next &raquo;'"
                :container-class="'pagination'"
              >
              </paginate>
            </nav>
          </div>-->
				</div>
			</div>
			<div class="row pt-20">
				<div class="col-md-2">
					<strong>Show Rec.</strong>
				</div>
				<div class="col-md-2">

					<select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
						<option value=10>10</option>
						<option value=25>25</option>
						<option value=50>50</option>
						<option value=100>100</option>
					</select>
				</div>
				<div class="col-md-8 text-right">
					<button class="btn bg-success btn-xs text-white" @click="generatepdf()">Print PDF</button>
				</div>
			</div>
			<div class="cardbox1">
				<div class="col-md-12" style="padding: 0px">
					<div class="table-responsive">
						<table class="table table-font table-bordered display">
							<tr class="card_1">
								<th>S.No</th>
								<th style="width: 20%">Product Name</th>
								<th>Category</th>
								<th>Company Name</th>
								<th>Model No.</th>
								<th>Colour</th>
								<th>Additional Scheme</th>
								<th>Action</th>
								<th>Action</th>
							</tr>
							<template v-if="disabledproducts">
								<product-edit-row v-for="(item, index) in productsdisabled" :key="item.id"
									:product="item" :currentpage="currentpage" :indx="index"
									:noofrec="noofrec"></product-edit-row>
							</template>
							<template v-if="!disabledproducts">
								<product-edit-row v-for="(item, index) in allproducts" :key="item.id" :product="item"
									:indx="index" :currentpage="currentpage" :noofrec="noofrec"></product-edit-row>
							</template>
						</table>
					</div>
				</div>
			</div>
			<div class="col-md-12" style="margin-top: 20px">
				<nav aria-label="Page navigation example" style="text-align: right !important">
					<paginate style="margin: 0px !important; float: right" :page-count="noofpages"
						:click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
						:container-class="'pagination'">
					</paginate>
				</nav>
			</div>
		</div>
		<div v-if="viewno == 2">
			<product-form></product-form>
		</div>
	</div>
</template>
<script>
// import Autocomplete from "vuejs-auto-complete";
import ProductEditRow from "./ProductEditRow";
import ProductForm from "./ProductForm";
import Constants from "../../components/utilities/Constants";
import Paginate from "vuejs-paginate";
import { mapGetters } from "vuex";
export default {
	mixins: [Constants],
	components: {
		ProductForm,
		ProductEditRow,
		// Autocomplete,
		Paginate
	},
	data() {
		return {
			categoryArray: [],
			status: { 1: "Disabled", 0: "Active" },
			isedit: false,
			product: null,
			size: 50,
			page: 1,
			removeitem: null,
			productcategorymap: [],
			productattribmap: [],
			predefined: [],
			searchname: "",
			searchcategory: "",
			searchrole: 0,
			searchstatus: 0,
			stockstatus: 0,
			categoryids: [],
			categoryid: 0,
			totalpage: 0,
			currentpage: 1,
			disabledproducts: false,
			productsdisabled: [],
			companies: [],
			brandid: 0,
			//productattribmap:[]
			noofrec: 10,
		};
	},
	mounted() {
		//this.$store.dispatch("fetchfeature")
		this.refresh();


	},
	computed: {
		filters() {
			return this.categories.filter((item) => item.parent_id > 0);
		},
		// companies(){
		// 	let brand = this.attributes.find((block) => block.id == 2);
		// 	return brand?.predefinedvalues
		// },
		...mapGetters([
			"viewno", 'allproductscount',
			"edititem",
			"perpagerecord",
			'attributes',
			"allproducts",
			"loggedinuser",
			"categories",
		]),
		noofpages() {
			return Math.ceil(parseInt(this.allproductscount) / parseInt(this.noofrec))
		},

	},
	methods: {


		clickCallback(currentpage) {
			this.currentpage = currentpage
			this.$store.commit("assigncurrentpage", currentpage);
			if (this.disabledproducts) {
				this.fetchDisabledProduct()
			} else {
				this.refresh();
			}

		},
		// distributionGroupsEndpoint(input) {
		// 	return (
		// 		window.axios.defaults.baseURL +
		// 		"/autocomplete/category/search?query=" +
		// 		input
		// 	);
		// },
		// addDistributionGroup(group) {
		// 	this.searchcategory = group.value;
		// },

		formattedDisplay(result) {
			return result.name;
		},
		processProductallListResponse(data) {
			console.log(data);
			this.$store.commit("assignloadingstatus", 0);
		},
		search() {
			this.$store.commit('assignloadingstatus', 1);
			// this.$store.dispatch("fetchproductscount");
			let param = {
				searchtxt: this.searchname,
				// searchstatus: this.searchstatus,
				// searchcategory: this.searchcategory,
				// searchrole: this.searchrole,
				image: this.images,
				brandid:this.brandid,

				categoryid: this.categoryid,
				currentpage: this.currentpage,
				noofrec: this.noofrec
			};
			this.$http.post("api/products/search", param)
				.then((response) => this.processProductResponse(response.data))
				.catch((err) => {
					this.errorShow(err);
				});
		},
		cancel() {
			this.$store.commit('assignloadingstatus', 0)
			this.categoryid = 0
			this.brandid=0,
			this.searchname=''
		},

		// clickCallback(currentpage) {
		// 	this.currentpage = currentpage
		// 	this.$store.commit("assigncurrentpage", currentpage);
		// 	this.refresh();
		// },
		// distributionGroupsEndpoint(input) {
		// 	return (
		// 		window.axios.defaults.baseURL +
		// 		"/autocomplete/category/search?query=" +
		// 		input
		// 	);
		// },
		// addDistributionGroup(group) {
		// 	this.searchcategory = group.value;
		// },

		processProductResponse(data) {
			this.$store.commit("assignloadingstatus", 0);
			this.$store.commit('assignallproducts', data.products);
			this.$store.commit('assignallproductscount', data.count);
			// this.$store.dispatch("fetchproductscount");
		},
		processCategoryResponse(data) {
			this.categories = data;
			this.$store.commit("assigncategories", data);
			this.categoryArray = data.reduce((acc, item) => {
				acc[item.id] = item.name;
				return acc;
			}, {});
			this.categoryArray[0] = "All/None";
		},
		triggeredit(item) {
			this.isedit = !this.isedit;
			this.product = item;
		},
		editingcompleted() {
			//if found then update otherwise add
			let index = this.products.findIndex(
				(block) => block.id == this.product.id
			);
			if (index > -1) {
				this.$set(this.products, index, this.product);
			} else {
				this.products.push(this.edititem);
			}
			this.processProductResponse(this.products);
		},
		triggerimagemodal(item) {
			this.product = item;
			this.$modal.show("imagemodal");
		},
		saveimage(imageid) {
			//post the category id and the image id
			let param = { productid: this.edititem.id, imageid: imageid };
			if (this.edititem != null) {
				this.$http
					.post("api/product/saveimage", param)
					.then((response) => {
						this.refreshimages(response.data);
					})
					.catch((err) => {
						this.errorShow(err);
					});
			}
		},
		refreshimages(data) {
			let product = this.allproducts.find(
				(block) => block.id == this.edititem.id
			);
			product.images = data;
		},
		processAttributes(data) {
			this.$store.commit("assignattributes", data);
			//this.createarrayofattributes();
		},
		getimageurl(imageitem) {
			//return window.axios.defaults.baseURL+"/products/"+imageitem.id+'/'+this.size+'x'+this.size+'/'+item.url+"-"+imageitem.id+".jpg";
			return window.axios.defaults.baseURL + "/uploads/" + imageitem.name;
		},

		checkboxevent(item, e) {
			console.log(e.target.checked);
			item.onsale = e.target.checked ? 1 : 0;
			this.$http.post("api/product", item);
		},
		refresh() {
			this.$store.commit("assignloadingstatus", 1);
			let param = { noofrec: this.noofrec ?? 50, currentpage: this.currentpage ?? 1 }
			this.$store.dispatch("fetchproducts", param);
			this.$store.dispatch("fetchcategories");
			this.$store.dispatch("fetchattributes");
			this.$store.dispatch("fetchproductscount");
			setTimeout(() => {
				if (this.attributes.length > 0) {
					let brand = this.attributes.find((block) => block.id == 2);
					this.companies = brand?.predefinedvalues
				}
			}, 2000);
			this.cancel();
			// this.$http
			// 	.post("api/products/fetchbyfilters/count")
			// 	.then((response) => this.processProductallListResponse(response.data))
			// 	.catch((err) => {
			// 		this.errorShow(err);
			// 	});

		},
		fetchDisabledProduct() {
			let param = {
				categoryid: this.categoryid, currentpage: this.currentpage,
				noofrec: this.noofrec
			}
			this.$http.post("api/product/disabled", param)
				.then((response) => this.processProductdisableResponse(response.data))
				.catch((err) => { this.errorShow(err); });
		},
		processProductdisableResponse(data) {
			this.$store.commit('assignloadingstatus', 0);
			this.productsdisabled = data.products
			this.$store.commit('assignallproductscount', data.count);


		},
		showdisabled() {
			this.disabledproducts = !this.disabledproducts;
			if (this.disabledproducts) {
				this.fetchDisabledProduct()
			} else {
				this.refresh()
			}
		},
	},
};
</script>
<style scoped>
.card_1 {
	position: relative;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	margin-bottom: 5px;
}

.table td,
.table th {
	border: 1px solid #dee2e6;
}

.example::-webkit-scrollbar {
	display: none;
}
</style>
